// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


import Headroom from './headroom'
window.Headroom = Headroom

import Rails from "@rails/ujs";
window.Rails = Rails;

import $ from 'jquery';

import "@hotwired/turbo-rails"

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');

require("bootstrap");

import ahoy from "ahoy.js";

const mediaQueryReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)");

var animationSkipped = false;

document.addEventListener('turbo:load', async (event) => {
  pageRender()
  
  var mainContent = document.getElementById("main_content");
  
  mainContent.classList.add('animated');
  
  $( ".site-header .collapse-btn" ).click(function() {
    console.log("Menu toggled")
    $( ".site-header" ).toggleClass( "menu-open" )
    $(this).attr('aria-expanded', function (i, attr) {
      return attr == 'true' ? 'false' : 'true'
    });
  });
})

function homePlayVideo() {
$('#transitionOverlay').removeClass("transition-show")  
  if ($('#frontPageFrame').length) {
    var video = document.getElementById("video");
    var promise = video.play();
    
    var unmutedOnce = false;
    
    if (promise !== undefined) {
      promise.then(_ => {
        console.log("Browser permitted auto-play.");
        unmutedOnce = true;
        changeButtonState('mute');
        $("#video_preshow").hide();
        $("#are_you_a_misfit").hide();
        $("#magic_circle_deny").hide();
      }).catch(error => {
        console.log("Browser prevented auto-play.")
      });
    }
  }
}

// this is not functioning as expected
// document.addEventListener('turbo:render', async (event) => {
//   // if this is ever present from cache, kill it
//   $('#transitionOverlay').removeClass("transition-show")
//   
//   if ($('#frontPageFrame').length) {
//     var video = document.getElementById("video");
//     var promise = video.play();
//     
//     var unmutedOnce = false;
//     
//     if (promise !== undefined) {
//       promise.then(_ => {
//         console.log("Browser permitted auto-play.");
//         unmutedOnce = true;
//         changeButtonState('mute');
//         $("#video_preshow").hide();
//         $("#are_you_a_misfit").hide();
//         $("#magic_circle_deny").hide();
//       }).catch(error => {
//         console.log("Browser prevented auto-play.")
//       });
//     }
//   }
// })

// might be redundant
// $( document ).ready(function() {
//   pageRender()
// });
  
function pageRender() {
  if (isTouchDevice()) {
    console.log("Device is touch");
    $( "body" ).addClass("touch-device");
  }
  
  if ($('#splashFrame').length) {
    var video = document.getElementById("video");
    var promise = video.play();
    
    var unmutedOnce = false;
    
    if (promise !== undefined) {
      promise.then(_ => {
        console.log("Browser permitted auto-play.");
        unmutedOnce = true;
        changeButtonState('mute');
        $("#video_preshow").hide();
        $("#are_you_a_misfit").hide();
        $("#magic_circle_deny").hide();
        $("#saviour_message").hide();
      }).catch(error => {
        console.log("Browser prevented auto-play. Click-to-play shown.");
        // Mute video and try to play again
        // video.muted = true;
        // video.play();
        // changeButtonState('mute');
        $("#video_preshow").show();
        $("#are_you_a_misfit").hide();
        $("#magic_circle_deny").hide();
        $("#saviour_message").hide();
    
        // Show something in the UI that the video is muted
      });
    }
    
    $("[data-action='play-splash']").click(function(e) {
      $("#video_preshow").fadeOut();
      
      video.muted = false;
      
      video.currentTime = 0;
      video.play();
      unmutedOnce = true;
      
      changeButtonState('mute');
      
      return false;
    });
    
    $("[data-action='play-splash-muted']").click(function(e) {
      $("#video_preshow").fadeOut();
      
      video.muted = true;
      
      video.currentTime = 0;
      video.play();
      unmutedOnce = true;
      
      changeButtonState('mute');
      
      return false;
    });
    
    video.onended = function() {
      // window.location.href = redirectURL;
      
      // are you a misfit?
      $("#are_you_a_misfit").fadeIn();
    };
    
    var playpause = document.getElementById('playpause');
    var mute = document.getElementById('mute');
    
    var changeButtonState = function(type) {
       // Play/Pause button
       if (type == 'playpause') {
          if (video.paused || video.ended) {
             playpause.setAttribute('data-state', 'play');
             playpause.innerHTML = "<i class='fas fa-fw fa-play'></i>";
          }
          else {
             playpause.setAttribute('data-state', 'pause');
              playpause.innerHTML = "<i class='fas fa-fw fa-pause'></i>";
          }
       }
       // Mute button
       else if (type == 'mute') {
          mute.setAttribute('data-state', video.muted ? 'unmute' : 'mute');
          mute.innerHTML = ('data-state', video.muted ? "<i class='fas fa-fw fa-volume-mute'></i>" : "<i class='fas fa-fw fa-volume'></i>");
       }
    }
    
    video.addEventListener('play', function() {
       changeButtonState('playpause');
    }, false);
    video.addEventListener('pause', function() {
       changeButtonState('playpause');
    }, false);
    mute.addEventListener('click', function(e) {
       video.muted = !video.muted;
       
       if (unmutedOnce == false) {
          video.currentTime = 0;
          unmutedOnce = true;
       }
       changeButtonState('mute');
    });
    
    // Add events for all buttons			
    playpause.addEventListener('click', function(e) {
      if (video.paused || video.ended) video.play();
      else video.pause();
    });
  }
  
  if ($('#frontPageFrame').length) {
    var video = document.getElementById("video");
    var promise = video.play();
    
    var unmutedOnce = false;
    
    if (promise !== undefined) {
      promise.then(_ => {
        console.log("Browser permitted auto-play.");
        unmutedOnce = true;
        changeButtonState('mute');
        $("#video_preshow").hide();
        $("#are_you_a_misfit").hide();
        $("#magic_circle_deny").hide();
      }).catch(error => {
        console.log("Browser prevented auto-play.")
      });
    }
  }
  
  if ($('#transitionOverlay').length) {
    console.log("Overlay present on page.")
    
    $("a").click(function(e) {
      $('#transitionOverlay').addClass("transition-show")
      
      var fireOverlayVideo = document.getElementById("fire_overlay");
      fireOverlayVideo.addEventListener('ended',overlayRedirect,false);
      var promise = fireOverlayVideo.play();
      
      var overlayHref = $(this).attr('href');
      console.log(overlayHref);
      
      function overlayRedirect(e) {
        window.location.href = overlayHref;
      }
      
      
      return false
    })
  }
  
  $("[data-action='select-wrong-answer']").click(function(e) {
    e.preventDefault();
    
    var vanessaWickedDice = Math.random() // between 0 to 1
    
    console.log(vanessaWickedDice)
    
    if(vanessaWickedDice > 0.95) {
      jumpScare()
    }
    else if (vanessaWickedDice > 0.66) {
      chosenForSalvation()
    }
    else {
      notAChristian()
    }
    
    function jumpScare() {
      // stub
      chosenForSalvation()
    }
    
    function chosenForSalvation() {
      $("#are_you_a_misfit").fadeOut(1000)
      $("#saviour_message").fadeIn(2000)
      
      const myTimeout = setTimeout(salvationRedirect, 6000);
    }
    
    function salvationRedirect(e) {
      window.location.href = "https://www.youtube.com/watch?v=jkpXJTggKbg";
    }
    
    function notAChristian() {
      $("#are_you_a_misfit").fadeOut()
      $("#magic_circle_deny").show()
      
      var tag = document.createElement('script');
      tag.id = 'iframe-demo';
      tag.src = 'https://www.youtube.com/iframe_api';
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    
      var player;
      function onYouTubeIframeAPIReady() {
        player = new YT.Player('shes_not_a_christian', {
            events: {
              'onReady': onPlayerReady
            }
        });
      }
      function onPlayerReady(event) {
        event.target.playVideo();
      }
      return false;
    }
  });
  
  var CookieDate = new Date;
  CookieDate.setFullYear(CookieDate.getFullYear() +1);

  $( ".site-header .alert" ).click(function() {
    $(this).slideUp("fast");
  });
  
  $(".playback-controls").show();

  const pressKitAppearance = getCookie('press_kit_appearance');

  if(pressKitAppearance == 'light') {
    addAppearanceClassesToPressKit();
    $(".presskit-frame #cbLightMode").prop('checked', true);
  }

  $(".presskit-frame #cbLightMode").click(function() {

    if($(this).prop('checked')) {
      addAppearanceClassesToPressKit();
      document.cookie = 'press_kit_appearance=light;expires='+CookieDate.toUTCString()+';path=/';
    }
    else {
      removeAppearanceClassesFromPressKit();
      document.cookie = 'press_kit_appearance=dark;expires='+CookieDate.toUTCString()+';path=/';
    }
  });
  
  function addAppearanceClassesToPressKit() {
    $(".presskit-frame").addClass("bg-light");
    $(".presskit-frame").addClass("presskit-frame-light");
  }

  function removeAppearanceClassesFromPressKit() {
    $(".presskit-frame").removeClass("bg-light");
    $(".presskit-frame").removeClass("presskit-frame-light");
  }

}

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

window.addEventListener('pageshow', function(event) {
  if (window.performance) {
    var navEntries = window.performance.getEntriesByType('navigation');
    if (navEntries.length > 0 && navEntries[0].type === 'back_forward') {
      console.log('Loaded via application JS disabling animation (used back button).');
      
      $('#transitionOverlay').removeClass("transition-show")
      homePlayVideo()
      
      $(".home-index-frame").addClass("limit-animation");
    } else if (window.performance.navigation && window.performance.navigation.type == window.performance.navigation.TYPE_BACK_FORWARD) {
      console.log('Loaded via application JS disabling animation (used back button).');
      
      $('#transitionOverlay').removeClass("transition-show")
      homePlayVideo()
      
      $(".home-index-frame").addClass("limit-animation");
    } else {
      console.log('Loaded without limiting animation.');
    }
  }
});

function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}